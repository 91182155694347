// @author: Mrunal Mane
// Created on: 2023-05-07
// Description: Task List Summary Page.

// @author: Mrunal Mane
// Modified on: 2023-05-23
// Description: Modified tooltip of line chart for custom dashboard

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';


import { Button, ButtonGroup, Dropdown, OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TaskListState } from "store/Reducers/GuideInsights/taskList";
import taskListReducer from "store/Reducers/GuideInsights/taskList";
import * as taskListActions from "store/Actions/index";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ApiRelativePaths, ChartColors } from "utils/GlobalConstants";
import { getCallExportExcel } from "utils/ApiCallActions";


// SCSS Imports
import "./TaskList.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import EventSource from 'components/EventSource/EventSource';
import { withReducer } from "store/withReducer";
import Loader from "components/Loader/Loader";

import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import { useErrorBoundary } from 'react-error-boundary';



import SectionFilters from "components/Layouts/SectionFilters";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import LineChart from "components/Chart/nvd3Charts/LineChart";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import UserInfoSection from "components/Layouts/UserInfoSection";

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    taskList: TaskListState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}

const TaskList: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dispatch = useDispatch();



    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0],
        showMaintenance_flag: false
    });


    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",

    });


    const [taskListChart, setTaskListChart] = useState({
        loadTaskListChartData: [],
        taskListDataFetched: false,
        api: '',
    });

    const [taskListSummary, setTaskListSummary] = useState({
        taskListFetched: false,
        searchQuery: '',
        sortKey: 'task_list_plays',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true
    });

    const [parameter, setParameter] = useState({
        paramter: "",
        // source: "",
    });


    const [request_uuid, setUuid] = useState({
        value: ''
    })

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    let linechart_height = 400;
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 5, 0, true, false);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.taskList ? insightsLbls.taskList : "" ;
    }, [insightsLbls])


    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
            };
        });

    }, []);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const taskListChartData = (reqUUID, apiErrorFlag?: string) => {
        try {
            let params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                'request_uuid': reqUUID
            };


            setTaskListChart((prevState: any) => {
                return {
                    ...prevState,
                    api: ['TASK_LIST_CHART']
                }
            })


            taskListActions.getTaskListData(
                params,
                "TASK_LIST_CHART",
                ActionTypes.SET_TASK_LIST_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));

        } catch (error) {
            showBoundary(error)
        }
    }


    const getTaskListSummary = (reqUUID, apiErrorFlag?: string) => {
        try {
            let params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                'sort_by': taskListSummary.sortKey,
                'order': taskListSummary.sortValue,
                'search_text': encodeURIComponent(taskListSummary.searchQuery),
                'page_index': taskListSummary.page,
                'page_size': taskListSummary.limit,
                'request_uuid': reqUUID
            };

            taskListActions.getTaskListData(
                params,
                "TASK_LIST_SUMMARY",
                ActionTypes.SET_TASK_LIST_SUMMARY,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));

        } catch (error) {
            showBoundary(error)
        }
    }

    const exportExcel = (reqUUID, data: any) => {
        try {
            let params: any;

            params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTaskList,
                'sort_by': taskListSummary.sortKey,
                'order': taskListSummary.sortValue,
                'search_text': encodeURIComponent(taskListSummary.searchQuery),
                'page_index': taskListSummary.page,
                'page_size': taskListSummary.limit,
                'request_uuid': reqUUID
            }

            getCallExportExcel(params, 'TASK_LIST_SUMMARY', data);
        } catch (error) {
            showBoundary(error)
        }

    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }


    const handleTableEvents = useCallback((option: string, data: any) => {
        try {

            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            paramter: data.taskListID,
                        };
                    });
                    break;

                case "sortColumn":
                    setTaskListSummary((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":
                    setTaskListSummary((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":

                    setTaskListSummary((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":
                    setTaskListSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(request_uuid.value, data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [taskListSummary]);





    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // UseEffect for date, app, filters change
    useEffect(() => {
        try {
            let queryParams = {
                app_id: fetched_details.commonComponentData.appsData.appId,
                app_code: fetched_details.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                env: fetched_details?.commonComponentData?.envFilter?.envCode,
            }

            navigate({
                pathname: `/guide-insights/task-list/`,
                search: qs.stringify(queryParams)
            })

            if (fetched_details?.commonComponentData.datesData.source === 'component' &&
                fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code')) {
                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                if (fetched_details?.commonComponentData.datesData?.url.includes('task-list/')) {

                    const reqUUID = uuidv4();

                    setTaskListChart((prevState: any) => {
                        return {
                            ...prevState,
                            loadTaskListChartData: [],
                            taskListDataFetched: false
                        };
                    });

                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })

                    enableExportPdf(0, false);
                    enableExportPdf(1, false);

                    setFilterValues((prevState: any) => {
                        return {
                            ...prevState,
                            eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceTaskList,
                        };
                    });

                    setTaskListSummary((prevState: any) => {
                        return {
                            ...prevState,
                            taskListFetched: false,
                            firstLoad: true,
                            searchQuery: '',
                            sortKey: 'task_list_plays',
                            filterLabels: [],
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 1,
                                'sortValue': 'desc'
                            },
                            // renderTaggedTable: false,
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                        }
                    })
                    taskListChartData(reqUUID);
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [
        fetched_details?.commonComponentData?.eventSource.eventSourceTaskList,
        fetched_details.commonComponentData.datesData])

    useEffect(() => {
        try {
            if (
                fetched_details?.taskList?.taskListChartData?.data !== undefined &&
                fetched_details?.taskList?.taskListChartData?.result === "success" &&
                fetched_details?.taskList?.taskListChartData?.requestUUID === request_uuid.value

            ) {
                let taskListChartData: any = [];

                taskListChartData = fetched_details?.taskList?.taskListChartData?.data?.graphData;
                setTaskListChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTaskListChartData: [...taskListChartData],
                        taskListDataFetched: true,
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details?.taskList?.taskListChartData?.result === 'retry') {
                taskListChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.taskList?.taskListChartData?.result === "error"
            ) {
                setTaskListChart((prevState: any) => {
                    return {
                        ...prevState,
                        taskListDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.taskList?.taskListChartData, request_uuid]);

    // Search Summary Data
    useEffect(() => {
        try {
            if (
                fetched_details.taskList.taskListSummaryData.data !== undefined &&
                fetched_details.taskList.taskListSummaryData.result === "success" &&
                fetched_details?.taskList.taskListSummaryData.requestUUID === request_uuid.value
            ) {

                setTaskListSummary((prevState: any) => {
                    return {
                        ...prevState,
                        taskListFetched: true,
                    }
                })

                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.taskList.taskListSummaryData.result === 'retry') {
                getTaskListSummary(request_uuid.value, 'retry');
            } else if (fetched_details.taskList.taskListSummaryData.result === "error") {
                setTaskListSummary((prevState: any) => {
                    return {
                        ...prevState,
                        taskListFetched: true,
                    }
                })
                apiCatchError(1, 1);
                enableExportPdf(1, false);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.taskList.taskListSummaryData.data]);

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData?.datesData?.url?.includes('task-list/') && request_uuid.value) {
                enableExportPdf(1, false);
                getTaskListSummary(request_uuid.value);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [taskListSummary.sortKey,
    taskListSummary.sortValue,
    taskListSummary.defaultSort,
    taskListSummary.page,
    taskListSummary.searchQuery,
    taskListSummary.limit]);


    //Export PDF Button
    useEffect(() => {
        try {

            if (loaderBtn.loader1 && loaderBtn.loader0) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader1, loaderBtn.loader0])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }, [errorCount.errorCount])

    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                }

                navigate({
                    pathname: "/guide-insights/task-list-detail/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [parameter])


    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setTaskListChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTaskListChartData: [],
                        taskListDataFetched: false
                    };
                });
                taskListChartData(request_uuid.value);
            } else if (optn === 1) {

                setTaskListSummary((prevState: any) => {
                    return {
                        ...prevState,
                        taskListFetched: false,
                        searchQuery: '',
                        sortKey: 'task_list_plays',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    };

    return (
        <section className="demo searchSection width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.taskList}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    {/* <EventSource component={"taskList"}></EventSource> */}
                    <Datepicker source={'component'}></Datepicker>
                </SectionFilters>
            </div>


            {/* <SectionButtons sectionTitle={insightsLbls.taskList} svgImage={"searches"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"TaskList"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"TaskList"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.taskListActivity}>
            </UserInfoSection>

            <div id="taskListSection">

                {
                    // <SectionRefresh sectionTitle={insightsLbls.taskListActivity} refreshClass={""}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(0)}></i>


                    // <CustomDashboardModal
                    //     api={taskListChart.api}
                    //     eventSource={filterValues.eventSource}
                    //     chartType={"LineChart"}
                    //     sectionHeader={insightsLbls.taskList}
                    //     sectionTooltip={
                    //         {
                    //             "subTitle": insightsLbls.activity,
                    //             "tooltipText": insightsLbls.taskListActivity,
                    //             "childern": [
                    //                 {
                    //                     "key": insightsLbls.taskListEngagementsLabel + ":",
                    //                     "value": insightsLbls.tasksTotalPlayed
                    //                 },
                    //                 {
                    //                     "key": insightsLbls.taskListCompletedLabel + ":",
                    //                     "value": insightsLbls.tasksCompleted
                    //                 }
                    //             ]
                    //         }
                    //     }
                    //     sectionType={'type1'}
                    //     guide_filter={false}
                    //     event_filter={false}
                    //     segment_filter={false}
                    //     tooltip_filter={false}
                    //     component={'taskList'}
                    // ></CustomDashboardModal>

                    // </SectionRefresh>
                }
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.taskListActivity,
                                    "childern": [
                                        {
                                            "key": insightsLbls.taskListEngagementsLabel + ":",
                                            "value": insightsLbls.tasksTotalPlayed
                                        },
                                        {
                                            "key": insightsLbls.taskListCompletedLabel + ":",
                                            "value": insightsLbls.tasksCompleted
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>

                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={taskListChart.api}
                                    eventSource={filterValues.eventSource}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.taskList}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.taskListActivity,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.taskListEngagementsLabel + ":",
                                                    "value": insightsLbls.tasksTotalPlayed
                                                },
                                                {
                                                    "key": insightsLbls.taskListCompletedLabel + ":",
                                                    "value": insightsLbls.tasksCompleted
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={false}
                                    segment_filter={false}
                                    tooltip_filter={false}
                                    component={'taskList'}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {taskListChart.taskListDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    taskListChart.loadTaskListChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <LineChart
                                            chartEntities={['task_list_plays', 'task_list_completed']}
                                            optionEntities={[
                                                {
                                                    key: insightsLbls.taskListEngagementsLabel,
                                                    color: ChartColors.color1,
                                                    associatedDataPoint: "task_list_plays",
                                                    area: true
                                                },
                                                {
                                                    key: insightsLbls.taskListCompletedLabel,
                                                    color: ChartColors.color2,
                                                    associatedDataPoint: "task_list_completed",
                                                    area: true
                                                }
                                            ]}
                                            chartData={fetched_details?.taskList?.taskListChartData?.data?.graphData}
                                            value_type={"count"}
                                            height={linechart_height}
                                            margin={lineChartMargin}
                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                            refId="taskList">
                                        </LineChart>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent minHeight401">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>


                {
                    // <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(1)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.taskListSummary,
                                    "tooltipText": insightsLbls.taskListSummary,
                                    "childern": [
                                        {
                                            "key": insightsLbls.taskListNameLabel + ":",
                                            "value": insightsLbls.taskListName
                                        },
                                        {
                                            "key": insightsLbls.taskListEngagementsLabel + ":",
                                            "value": insightsLbls.taskListEngagements
                                        },
                                        {
                                            "key": insightsLbls.taskListCompletedLabel + ":",
                                            "value": insightsLbls.taskListCompleted
                                        },
                                        {
                                            "key": insightsLbls.taskListLastUpdatedLabel + ":",
                                            "value": insightsLbls.taskListLastUpdated
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>


                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                taskListSummary.taskListFetched ? (
                                    errorCount.errorCount[1] === 0 ? (
                                        <div className=" commonSummaryTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.taskList.taskListSummaryData.data}
                                                currentPage={taskListSummary.page}
                                                barColors=""
                                                linkPresent={true}
                                                showExport={false}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={taskListSummary.defaultSort}
                                                tablePagination={taskListSummary.tablePagination}
                                                tableEvent={handleTableEvents}
                                                firstTimeloadFlag={fetched_details.taskList.taskListSummaryData.firstTimeload}
                                                modalTable={false}

                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[1] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </CardContainer>
                </div>

            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}

export default withRouter(withReducer("taskList", taskListReducer)(TaskList));

