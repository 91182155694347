// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import withRouter from "hoc/withRouter";


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, EventSourceState, LanguageState } from "store/Reducers";
import * as ActionTypes from "store/Actions/ActionTypes";
import { TooltipsDetailState } from "store/Reducers/GuideInsights/tooltipsDetail";
import tooltipsDetailReducer from "store/Reducers/GuideInsights/tooltipsDetail";
import * as tooltipsDetailActions from "store/Actions/index";

import { ChartColors } from "utils/GlobalConstants";
import { CommonUtils } from "utils/CommonUtils";
import qs from 'querystring';


// SCSS Imports
import "./TooltipsDetail.scss";


import Loader from "components/Loader/Loader";
import SectionHeader from 'components/Layouts/SectionHeader';
import Datepicker from 'components/Calender/Datepicker';
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
import UserActivity from "components/CommonModal/UserActivity/UserActivity";
import Maintenance from "components/Maintenance/Maintenance";
import Linechart from "components/Chart/nvd3Charts/LineChart"
import BarChart from "components/Chart/nvd3Charts/BarChart";
import { useErrorBoundary } from 'react-error-boundary';
import SectionFilters from "components/Layouts/SectionFilters";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { InsightLabels } from "labels";
import UserInfoSection from "components/Layouts/UserInfoSection";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

import Icon from 'components/Icon/Icon';
import Piechart from "components/Chart/nvd3Charts/Piechart";
import CommonTable from "components/Table/CommonTable";

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }
interface CurrentState {
    tooltipsDetails: TooltipsDetailState,
    eventSource: EventSourceState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const TooltipsDetail: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    // CommonUtils.LeftPanelHighlight(1, 2, 0, false, false);
    const location = useLocation();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let urlData: any = {};

    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });


    const { tooltip_id } = useParams();
    const tooltipId = tooltip_id;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let fetched_details = useSelector((state: CurrentState) => state);

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    };

    let linechart_height = 400;

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });
    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        disabled: true,
    });
    const [listData, setListData] = useState({
        tooltipsData: [],
        tooltipsDataFetched: false,
        errorMsg: ""
    });
    const [tooltipDetail, setTooltipDetail] = useState({
        tooltipName: '',
        tooltipID: null,
        tooltipType: '',
    })
    const [tooltipsDetailActivityChartData, setTooltipsDetailActivityChartData] = useState({
        loadChartData: [],
        loadChartOptions: [],
        tooltipsDetailActivityChartDataFetched: false,
        errorMsg: '',
        warningFlag: false
    });
    const [tooltipsDetailDuctTapeChartData, setTooltipsDetailDuctTapeChartData] = useState({
        loadChartData: [],
        loadChartOptions: [],
        tooltipsDetailDuctTapeChartDataFetched: false,
        errorMsg: '',
        warningFlag: false
    });
    const [tooltipsDetailPowerformChartData, setTooltipsDetailPowerFormChartData] = useState({
        loadPowerFormChartData: [],
        loadPowerFormChartOptions: [],
        loadPowerFormBarChartOptions: [],
        tooltipsDetailPowerformChartDataFetched: false,
        errorMsg: '',
        warningFlag: false

    });
    const [tooltipsDetailLinkClickData, setTooltipsDetailLinkClickData] = useState({
        linkClickChartData: [],
        linkClickChartDataOptions: [],
        linkClickChartDataFetched: false,
        errorMsg: '',
        warningFlag: false,
        visibilityFlag: false
    })

    const [showLegendDropdown, setShowLegendDropdown] = useState(false);

    const [legendDropdownVal, setLegendDropdownVal] = useState('');

    const [userActivityModal, setUserActivityModal] = useState({
        modalOpen: false
    })

    const [tooltipTitle, setTooltipTitle] = useState('');

    const [linkEventSummary, setLinkEventSummary] = useState({
        summaryFetched: false,
        searchQuery: '',
        sortKey: 'interaction_count',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 4,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls !== undefined) {
            document.title = insightsLbls['docTitleTooltipDetails'] ? insightsLbls['docTitleTooltipDetails'] : "";
        }

        CommonUtils.LeftPanelHighlight(1, 4, 3, true, false);
        window.scrollTo(0, 0);
    }, [insightsLbls])

    useEffect(() => {
        let queryParams: any = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
        }

        let component_path: any[] = location.pathname.split('/');
        component_path = component_path.filter(function (el) {
            return el != '';
        });

        navigate({
            pathname: "/guide-insights/tooltips-detail/" + component_path[component_path.length - 1] + '/',
            search: qs.stringify(queryParams),
        });

        if (fetched_details?.commonComponentData.datesData.source === 'component') {
            if (fetched_details?.commonComponentData?.datesData?.url?.includes('tooltips-detail')) {
                getTooltipsDetailListData();
            }
        }

    }, [fetched_details?.commonComponentData.datesData]);


    // Api calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getTooltipsDetailListData = (apiErrorFlag?: string) => {
        let params = {
            "tooltip_id": tooltipId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "event_source": fetched_details.eventSource.eventSourceTooltips,
            "page_size": 99999,
            "page_index": 1,
            "search_text": '',
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': localStorage.getItem('ENABLE_REAL_TIME') === 'true' ? true : false,
            'app_id': fetched_details?.commonComponentData.appsData.appId,
            'env_code': fetched_details?.commonComponentData?.envFilter.envCode ?? ''
        };

        tooltipsDetailActions.getTooltipsDetailData(
            params,
            "TOOLTIPS_DETAIL_LIST_DATA",
            ActionTypes.SET_TOOLTIPS_DETAIL_LIST_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details.eventSource.eventSourceTooltips,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': localStorage.getItem('ENABLE_REAL_TIME') === 'true' ? true : false,
            'guide_id': tooltipId,
            'tooltip_id': tooltipDetail.tooltipID,
            'app_id': fetched_details?.commonComponentData.appsData.appId,
            'env_code': fetched_details?.commonComponentData?.envFilter.envCode ?? ''
        };

        if (tooltipDetail.tooltipID) {
            tooltipsDetailActions.getTooltipsDetailData(
                params,
                "TOOLTIPS_DETAIL_CHART",
                ActionTypes.SET_TOOLTIPS_DETAIL_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }
    };

    const getUserEngagementPowerFormChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details.eventSource.eventSourceTooltips,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': localStorage.getItem('ENABLE_REAL_TIME') === 'true' ? true : false,
            'guide_id': tooltipId,
            'tooltip_id': tooltipDetail.tooltipID,
            'app_id': fetched_details?.commonComponentData.appsData.appId,
            'env_code': fetched_details?.commonComponentData?.envFilter.envCode ?? ''
        };

        if (tooltipDetail.tooltipID) {
            tooltipsDetailActions.getTooltipsDetailData(
                params,
                "TOOLTIPS_DETAIL_POWERFORM_CHART",
                ActionTypes.SET_TOOLTIPS_DETAIL_POWERFORM_CHART,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        }
    };

    const getTooltipsDetailLinkClickData = (apiErrorFlag?: string) => {
        let params = {
            'guide_id': tooltipId,
            "tooltip_id": tooltipDetail.tooltipID,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "event_source": fetched_details.eventSource.eventSourceTooltips,
            "page_size": 99999,
            "page_index": 1,
            "search_text": '',
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': localStorage.getItem('ENABLE_REAL_TIME') === 'true' ? true : false,
            'app_id': fetched_details?.commonComponentData.appsData.appId,
            'env_code': fetched_details?.commonComponentData?.envFilter.envCode ?? ''
        };

        tooltipsDetailActions.getTooltipsDetailData(
            params,
            "TOOLTIPS_DETAIL_LINK_CLICK",
            ActionTypes.SET_TOOLTIPS_DETAIL_LINK_CLICK_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

     const getLinkEventSummaryData = (apiErrorFlag?: string) => {
            let params = {
                "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "time_zone": timeZone,
                "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
                'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                'page_index': linkEventSummary.page,
                'page_size': linkEventSummary.limit,
                'sort_by': linkEventSummary.sortKey,
                'order': linkEventSummary.sortValue,
                'search_text': encodeURIComponent(linkEventSummary.searchQuery),
                'guide_id': tooltipId,
            };


    
            tooltipsDetailActions.getTooltipsDetailData(
                params,
                "LINK_EVENT_SUMMARY",
                ActionTypes.SET_LINK_EVENT_SUMMARY,
                apiErrorFlag ?? '',
                linkEventSummary.firstLoad,
                ''
            ).then((res: any) => dispatch(res));
     }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false)

            if (optn === 0) {
                setTooltipsDetailActivityChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailActivityChartDataFetched: false,
                    };
                });
                setTooltipsDetailDuctTapeChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailDuctTapeChartDataFetched: false,
                    };
                });
                setTooltipsDetailPowerFormChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailPowerformChartDataFetched: false,
                    };
                });
                getUserEngagementChartData();
                getUserEngagementPowerFormChartData();
                getTooltipsDetailLinkClickData();
            } else if(optn === 1){
                setLinkEventSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: false,
                        searchQuery: '',
                        sortKey: 'interaction_count',
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 4,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true,
                        hideDeleted: 0,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const getStepStatus = (step: any) => {
        try {
            setTooltipsDetailLinkClickData((prevState: any) => {
                return {
                    ...prevState,
                    visibilityFlag: false
                }
            })

            const name = step['label']
            const id = step['value'];
            const tooltipType = step['type']
            setTooltipDetail((prevState: any) => {
                return {
                    ...prevState,
                    tooltipName: name,
                    tooltipID: id,
                    tooltipType: tooltipType,
                }
            })


        } catch (error) {
            showBoundary(error)
        }

    }

    const openUserAcitivtyModal = () => {
        try {
            setUserActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeUserActivityModal = useCallback(() => {
        try {
            setUserActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, []);


    const formatChartDataForPiechart = (data) => {

        const res = {
            submit: 0,
            cancel: 0
        }
        data.forEach((datapoint) => {
            res.submit += datapoint['counts']['submit']
            res.cancel += datapoint['counts']['cancel']
        })
        return [{
            counts: res
        }];
    }

    const formatChartEntitiesForPiechart = () => {
        return ['submit', 'cancel'];
    }
    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Api calls on tooltipId change
    useEffect(() => {
        try {
            if (tooltipDetail.tooltipID !== null) {
                enableExportPdf(0, false)
                enableExportPdf(1, false)

                setTooltipsDetailActivityChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailActivityChartDataFetched: false,
                    };
                });
                setTooltipsDetailDuctTapeChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailDuctTapeChartDataFetched: false,
                    };
                });
                setTooltipsDetailPowerFormChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailPowerformChartDataFetched: false,
                    };
                });

                getUserEngagementChartData();
                getUserEngagementPowerFormChartData();
                getTooltipsDetailLinkClickData();
                getLinkEventSummaryData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [tooltipDetail.tooltipID]);

    // Tooltip list data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltipsDetails.tooltipsDetailListData.data !== undefined &&
                fetched_details.tooltipsDetails.tooltipsDetailListData.result === "success"
            ) {
                let dummyData: any = [];
                for (let data of fetched_details.tooltipsDetails.tooltipsDetailListData.data.data) {
                    dummyData.push({ tooltipName: data['step_title'], tooltipID: data['step_id'], tooltipType: data['step_type'] })
                }

                if (fetched_details?.tooltipsDetails?.tooltipsDetailListData?.data?.data[0]) {
                    setTooltipTitle(fetched_details?.tooltipsDetails?.tooltipsDetailListData?.data?.data[0]['guide_title'])
                }

                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsData: dummyData,
                        tooltipsDataFetched: true,
                        errorMsg: ''
                    }
                })
                apiCatchError(3, 0);

            } else if (fetched_details.tooltipsDetails.tooltipsDetailListData.result === 'retry') {
                getTooltipsDetailListData('retry');
            } else if (fetched_details.tooltipsDetails.tooltipsDetailListData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.tooltipsDetails.tooltipsDetailListData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDataFetched: true,
                        errorMsg: errorMsg
                    }
                })
                apiCatchError(3, errorFlag);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.tooltipsDetails.tooltipsDetailListData]);

    // Tooltip chart data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltipsDetails.tooltipsDetailChartData.data !== undefined &&
                fetched_details.tooltipsDetails.tooltipsDetailChartData.result === "success"
            ) {

                let chartData: any = [];

                chartData = fetched_details.tooltipsDetails.tooltipsDetailChartData.data.graphData;
                renderActivityLineChart(chartData);
                renderDucttapeLineChart(chartData);
                apiCatchError(0, 0);
                enableExportPdf(0, true)

                setTooltipsDetailActivityChartData((prevState: any) => ({
                    ...prevState,
                    warningFlag: fetched_details?.tooltipsDetails?.tooltipsDetailChartData?.data?.loggedDataBypassed ?? false
                }))

                setTooltipsDetailDuctTapeChartData((prevState: any) => ({
                    ...prevState,
                    warningFlag: fetched_details?.tooltipsDetails?.tooltipsDetailChartData?.data?.loggedDataBypassed ?? false
                }))

            } else if (fetched_details.tooltipsDetails.tooltipsDetailChartData.result === 'retry') {
                getUserEngagementChartData('retry');
            } else if (fetched_details.tooltipsDetails.tooltipsDetailChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.tooltipsDetails.tooltipsDetailChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setTooltipsDetailActivityChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailActivityChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                setTooltipsDetailDuctTapeChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailDuctTapeChartDataFetched: false,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.tooltipsDetails.tooltipsDetailChartData]);

    // Powerform chart data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData.data !== undefined &&
                fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData.result === "success"
            ) {
                let powerFormChartData: any = [];

                powerFormChartData = fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData.data.graphData;
                powerFormChartData = CommonUtils.dateSeparator(powerFormChartData);

                let cancel: any = [];
                let submit: any = [];

                for (let i = 0; i < powerFormChartData.length; i++) {
                    cancel[i] = {};
                    cancel[i].y = parseInt(powerFormChartData[i].counts.cancel, 10);
                    cancel[i].x = powerFormChartData[i].startTimestamp;
                    cancel[i].startTimestamp = powerFormChartData[i].startTimestamp;
                    cancel[i].date = powerFormChartData[i].date;

                    submit[i] = {};
                    submit[i].y = parseInt(powerFormChartData[i].counts.submit, 10);
                    submit[i].x = powerFormChartData[i].startTimestamp;
                    submit[i].startTimestamp = powerFormChartData[i].startTimestamp;
                    submit[i].date = powerFormChartData[i].date;
                }

                const powerFormChartOptions = [
                    {
                        key: insightsLbls !== undefined && insightsLbls['submitted'],
                        values: submit,
                        area: false,
                        color: ChartColors.color1,
                    },
                    {
                        key: insightsLbls !== undefined && insightsLbls['cancelled'],
                        values: cancel,
                        area: false,
                        color: ChartColors.color2,
                    }
                ];


                setTooltipsDetailPowerFormChartData((prevState: any) => {
                    return {
                        ...prevState,
                        loadPowerFormChartData: [...powerFormChartData],
                        loadPowerFormChartOptions: [...powerFormChartOptions],
                        tooltipsDetailPowerformChartDataFetched: true,
                        errorMsg: '',
                        warningFlag: fetched_details?.tooltipsDetails?.tooltipsDetailPowerformChartData?.data?.loggedDataBypassed ?? false

                    };
                });

                apiCatchError(2, 0);
                enableExportPdf(1, true)
            } else if (fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData.result === 'retry') {
                getUserEngagementPowerFormChartData('retry');
            } else if (
                fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setTooltipsDetailPowerFormChartData((prevState: any) => {
                    return {
                        ...prevState,
                        tooltipsDetailPowerformChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(2, errorFlag);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.tooltipsDetails.tooltipsDetailPowerformChartData]);

    //Tooltip Link Click data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltipsDetails.tooltipDetailsLinkClickData.data !== undefined &&
                fetched_details.tooltipsDetails.tooltipDetailsLinkClickData.result === "success"
            ) {
                // let dummyData: any = [];
                // for (
                let data = fetched_details.tooltipsDetails.tooltipDetailsLinkClickData.data;
                //     dummyData.push({ tooltipName: data['step_title'], tooltipID: data['step_id'], tooltipType: data['step_type'] })
                // }

                // if (fetched_details?.tooltipsDetails?.tooltipsDetailListData?.data?.data[0]) {
                //     setTooltipTitle(fetched_details?.tooltipsDetails?.tooltipsDetailListData?.data?.data[0]['guide_title'])
                // }
                setTooltipsDetailLinkClickData((prevState: any) => {
                    return {
                        ...prevState,
                        linkClickChartData: data.graphData,
                        linkClickChartDataOptions: data.legends,
                        linkClickChartDataFetched: true,
                        errorMsg: '',
                        visibilityFlag: true
                    }
                })
                apiCatchError(4, 0);

            } else if (fetched_details.tooltipsDetails.tooltipsDetailListData.result === 'retry') {
                getTooltipsDetailLinkClickData('retry');
            } else if (fetched_details.tooltipsDetails.tooltipsDetailListData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.tooltipsDetails.tooltipsDetailListData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setTooltipsDetailLinkClickData((prevState: any) => {
                    return {
                        ...prevState,
                        linkClickChartDataFetched: true,
                        errorMsg: errorMsg,
                        visibilityFlag: true
                    }
                })
                apiCatchError(4, errorFlag);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.tooltipsDetails.tooltipDetailsLinkClickData]);


        useEffect(() => {
            try {
              
                if (
                    fetched_details.tooltipsDetails.linkEventSummary.data !== undefined &&
                    fetched_details.tooltipsDetails.linkEventSummary.result === "success" 
                ) {
                    setLinkEventSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: true,
                        }
                    })
                } else if (fetched_details.tooltipsDetails.linkEventSummary.result === 'retry') {
                    getLinkEventSummaryData( 'retry');
                } else if (fetched_details.tooltipsDetails.linkEventSummary.result === "error") {
                    let errorMsg = insightsLbls.serviceNotAvailable;
                    let errorFlag = 1;
                    if (fetched_details.tooltipsDetails.tooltipsDetailListData.errorCode === 409) {
                        errorMsg = insightsLbls.conflictingParameters;
                        errorFlag = 2;
                    }
    
                    setLinkEventSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: true,
                        }
                    })
                    apiCatchError(5, errorFlag);
                }
            } catch (error) {
                showBoundary(error)
            }
    
        }, [fetched_details.tooltipsDetails.linkEventSummary]);

            // Api call on table actions (SmartTip summary)
            useEffect(() => {
                try {
                    // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                    if (fetched_details?.commonComponentData.datesData?.url?.includes('tooltips-detail/') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
                        // enableExportPdf(3, false);
                        getLinkEventSummaryData();
                    }
                } catch (error) {
                    showBoundary(error)
                }
        
            }, [
                linkEventSummary.sortKey,
                linkEventSummary.sortValue,
                linkEventSummary.defaultSort,
                linkEventSummary.page,
                linkEventSummary.searchQuery,
                linkEventSummary.limit,
                linkEventSummary.hideDeleted,
            ]);

    // Export pdf btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })

        } catch (error) {
            showBoundary(error)
        }

    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const renderActivityLineChart = (data: any) => {
        try {
            setTooltipsDetailActivityChartData((prevState: any) => {
                return {
                    ...prevState,
                    loadChartData: [...data],
                    tooltipsDetailActivityChartDataFetched: true
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const renderDucttapeLineChart = (data: any) => {
        try {
            setTooltipsDetailDuctTapeChartData((prevState: any) => {
                return {
                    ...prevState,
                    loadChartData: [...data],
                    tooltipsDetailDuctTapeChartDataFetched: true
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const getActivityLineChartData = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {
                    return tooltipsDetailActivityChartData.loadChartData;
                } else {
                    return tooltipsDetailActivityChartData.loadChartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [tooltipsDetailActivityChartData.loadChartData, tooltipsDetailActivityChartData.loadChartOptions]
    );

    const getDuctTapeLineChartData = useCallback(
        (optn: string) => {
            try {
                if (optn === "data") {

                    return tooltipsDetailDuctTapeChartData.loadChartData;
                } else {
                    return tooltipsDetailDuctTapeChartData.loadChartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [tooltipsDetailDuctTapeChartData.loadChartData, tooltipsDetailDuctTapeChartData.loadChartOptions]
    );

    const getPowerFormLineChartData = useCallback(
        (optn: string, type: string) => {
            try {
                if (optn === "data") {
                    return tooltipsDetailPowerformChartData.loadPowerFormChartData;
                } else {
                    return type === 'linechart' ? tooltipsDetailPowerformChartData.loadPowerFormChartOptions : tooltipsDetailPowerformChartData.loadPowerFormBarChartOptions;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [tooltipsDetailPowerformChartData.loadPowerFormChartData, tooltipsDetailPowerformChartData.loadPowerFormChartOptions, tooltipsDetailPowerformChartData.loadPowerFormBarChartOptions]
    );

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "sortColumn":
                    setLinkEventSummary((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":

                    setLinkEventSummary((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setLinkEventSummary((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setLinkEventSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false,
                        }
                    });
                    break;

                case "hideDeleted":
                    setLinkEventSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    // exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [linkEventSummary]);


    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }

        navigate({
            pathname: `/guide-insights/tooltips/`,
            search: qs.stringify(queryParams)
        })
    }

    const getGuidanceTooltipLineChart = () => {
        return <Linechart
            chartData={getActivityLineChartData("data")}
            chartEntities={['guidance', 'validation', 'guidanceValidation']}
            optionEntities={[
                {
                    key: insightsLbls !== undefined && insightsLbls['tooltip'],
                    color: ChartColors.color1,
                    associatedDataPoint: "guidance",
                    area: false
                },
                {
                    key: insightsLbls !== undefined && insightsLbls['validation'],
                    color: ChartColors.color2,
                    associatedDataPoint: "validation",
                    area: false,
                },
                {
                    key: insightsLbls !== undefined && insightsLbls['tooltipAndValidation'],
                    color: ChartColors.color3,
                    associatedDataPoint: "guidanceValidation",
                    area: false
                }
            ]}
            value_type={"count"}
            height={linechart_height}
            margin={lineChartMargin}
        ></Linechart>
    }

    const getGuidanceTooltipTemplate = () => {
        return <>
            {getGuidanceTooltipLineChart()}
            {tooltipsDetailActivityChartData.warningFlag && (
                <div className="warningDiv">

                    <p>{insightsLbls.chartLoadWarningMsg}
                        <button
                            type="button"
                            className="closeWarning"
                            onClick={() =>
                                setTooltipsDetailActivityChartData((prevState: any) => ({
                                    ...prevState,
                                    warningFlag: false,
                                }))
                            }
                        >
                            <span aria-hidden="true">
                                <Icon img="img/close-icon-large.svg" />
                            </span>
                            <span className="sr-only">Close</span>
                        </button>
                    </p>
                </div>
            )}
        </>
    }

    const getLegendDropdownTemplate = () => {
        return <Dropdown className="width-100" show={showLegendDropdown} onToggle={(event) => setShowLegendDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary legend-dropdown"} style={{ zIndex: "1" }}>
                <span className="dropdown-label">{legendDropdownVal}</span>
                <img src={require("../../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${showLegendDropdown ? 'show' : ''}`}>
                {
                    tooltipsDetailLinkClickData.linkClickChartDataOptions.map((option: any, index: number) => {
                        return (
                            <div className={`custom-control ${legendDropdownVal === option.legendTitle ? 'active' : ''}`}
                                key={index} onClick={() => setLegendDropdownVal(option.legendTitle)}>
                                <label>
                                    <span>{option.legendTitle}</span>
                                </label>
                            </div>
                        );
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    }

    const getAttr = attr => tooltipsDetailLinkClickData.linkClickChartDataOptions.find(option => option.legendTitle === legendDropdownVal)?.[attr];

    const getLinkClickChartTemplate = () => {
        return <Linechart
            chartData={tooltipsDetailLinkClickData.linkClickChartData}
            chartEntities={[getAttr('associatedDataPoint')]}
            optionEntities={[
                {
                    key: legendDropdownVal,
                    color: getAttr('color'),
                    associatedDataPoint: getAttr('associatedDataPoint'),
                    area: false
                }
            ]}
            value_type={"count"}
            height={linechart_height}
            margin={lineChartMargin}
        ></Linechart>

    }

    const getLinkClickTemplate = () => {
        if (tooltipsDetailLinkClickData.linkClickChartDataFetched) {
            if (errorCount.errorCount[3] === 0) {
                if (tooltipsDetailLinkClickData.linkClickChartDataOptions.length > 0) {
                    if (legendDropdownVal === '') {
                        setLegendDropdownVal(tooltipsDetailLinkClickData.linkClickChartDataOptions[0].legendTitle);
                    }
                    return <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <div className="tooltip-detail">
                            <div className="legend-dropdown-container">
                                {getContentContainerTemplate({
                                    "subTitle": insightsLbls.totalURLClicks,
                                    "tooltipText": insightsLbls.totalUrlClicksGraph,
                                })}
                                {getLegendDropdownTemplate()}
                            </div>
                            <hr></hr>
                            {getLinkClickChartTemplate()}
                        </div>
                    </CardContainer>
                } else {
                    return getNoRecordFoundTemplate();
                }
            } else {
                return getErrorMessageTemplate(listData.errorMsg);
            }
        } else {
            return getLoaderTemplate();
        }
        return null;
    }

    const getLoaderTemplate = () => {
        return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
            <div className="displayFlex alignCenter margin-0-auto">
                <Loader></Loader>
            </div>
        </div>
    }

    const getErrorMessageTemplate = (errorMsg) => {
        return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
            <div className="displayFlex alignCenter margin-0-auto">
                <h5 className="errorCss card-heading font-weight-400 text-center">
                    {errorMsg}
                </h5>
            </div>
        </div>
    }

    const getNoRecordFoundTemplate = () => {
        return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
            <div className="displayFlex alignCenter margin-0-auto">
                <div className="noDataText-font textCenter-absolute">
                    {insightsLbls.noRecordFound}
                </div>
            </div>
        </div>
    }

    const getContentContainerTemplate = (tooltipText) => {
        return <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
            <SectionTooltip tooltipText={tooltipText} placement="right"></SectionTooltip>
        </ContainerTooltip>
    }

    const getTemplate = () => {
        if (tooltipDetail.tooltipType === 'guidance' || tooltipDetail.tooltipType === 'validation' || tooltipDetail.tooltipType === 'validation_and_guidance') {
            return <>
                {getContentContainerTemplate({
                    "subTitle": insightsLbls.uniqueTooltipValidationActivity,
                    "tooltipText": insightsLbls.tooltipGuidanceAndValidationGraph,
                    "childern": [
                        {
                            "key": insightsLbls.tooltip + ":",
                            "value": insightsLbls.countOfTooltipClickedAndHovered
                        },
                        {
                            "key": insightsLbls.validation + ":",
                            "value": insightsLbls.countOfValidationPerformed
                        },
                        {
                            "key": insightsLbls.tooltipAndValidation + ":",
                            "value": insightsLbls.countTooltipShownAndValidationPerformed
                        }
                    ]
                })}

                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section borderBottom minHeight450 padding0 positionRelative">
                    {tooltipsDetailActivityChartData.tooltipsDetailActivityChartDataFetched ? (
                        errorCount.errorCount[1] === 0 ? (
                            tooltipsDetailActivityChartData.loadChartData.length === 0 ? getNoRecordFoundTemplate() : getGuidanceTooltipTemplate()
                        ) : getErrorMessageTemplate(tooltipsDetailActivityChartData.errorMsg)
                    ) : getLoaderTemplate()}
                </div>
            </>
        } else if (tooltipDetail.tooltipType === 'digital_duct_tape') {
            return <>
                {getContentContainerTemplate({
                    "subTitle": insightsLbls.uniqueDuctTapeActivity,
                    "tooltipText": insightsLbls.statsOfDigitalDuctTape,
                    "childern": [
                        {
                            "key": insightsLbls.digitalDuctTapeLabel,
                            "value": insightsLbls.countDigitalDuctTapeDisplayed
                        }
                    ]
                })}
                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section borderBottom minHeight450 padding0 positionRelative">
                    {tooltipsDetailDuctTapeChartData.tooltipsDetailDuctTapeChartDataFetched ? (
                        errorCount.errorCount[1] === 0 ? (
                            tooltipsDetailDuctTapeChartData.loadChartData.length === 0 ? getNoRecordFoundTemplate() : (
                                <>
                                    <Linechart
                                        chartData={getDuctTapeLineChartData("data")}
                                        chartEntities={['digitalDuctTape']}
                                        optionEntities={[
                                            {
                                                key: insightsLbls !== undefined && insightsLbls['ductTape'],
                                                color: ChartColors.color4,
                                                associatedDataPoint: "digitalDuctTape",
                                                area: false
                                            },
                                        ]}
                                        value_type={"count"}
                                        height={linechart_height}
                                        margin={lineChartMargin}
                                    ></Linechart>
                                    {tooltipsDetailDuctTapeChartData.warningFlag && (
                                        <div className="warningDiv">

                                            <p>{insightsLbls.chartLoadWarningMsg}
                                                <button
                                                    type="button"
                                                    className="closeWarning"
                                                    onClick={() =>
                                                        setTooltipsDetailDuctTapeChartData((prevState: any) => ({
                                                            ...prevState,
                                                            warningFlag: false,
                                                        }))
                                                    }
                                                >
                                                    <span aria-hidden="true">
                                                        <Icon img="img/close-icon-large.svg" />
                                                    </span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                                            </p>
                                        </div>
                                    )}
                                </>

                            )
                        ) : getErrorMessageTemplate(tooltipsDetailDuctTapeChartData.errorMsg)
                    ) : getLoaderTemplate()}
                </div>
            </>
        } else if (tooltipDetail.tooltipType === 'power_html') {
            return <>
                {getContentContainerTemplate({
                    "subTitle": insightsLbls.uniquePowerFormActivity,
                    "tooltipText": insightsLbls.barChartPowerForm,
                    "childern": [
                        {
                            "key": insightsLbls.submitted + ":",
                            "value": insightsLbls.countOfSubmittedPowerForm
                        },
                        {
                            "key": insightsLbls.cancelled + ":",
                            "value": insightsLbls.countOfCancelledPowerForm
                        }
                    ]
                })}
                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section ">
                    {tooltipsDetailPowerformChartData.tooltipsDetailPowerformChartDataFetched ? (
                        errorCount.errorCount[2] === 0 ? (
                            tooltipsDetailPowerformChartData.loadPowerFormChartData.length === 0 ? getNoRecordFoundTemplate() : (
                                <>
                                    <div className="displayFlex justifyCenter marginLR-10 marginBottom-20">
                                        <div className="col-8 marginTop-20 outer-border marginRight-10 paddingRight-10 paddingTop-10">
                                            <Linechart
                                                chartData={getPowerFormLineChartData("data", "linechart")}
                                                chartEntities={['submit', 'cancel']}
                                                optionEntities={[
                                                    {
                                                        key: insightsLbls !== undefined && insightsLbls['submitted'],
                                                        color: ChartColors.color4,
                                                        associatedDataPoint: "submit",
                                                        area: false
                                                    },
                                                    {
                                                        key: insightsLbls !== undefined && insightsLbls['cancelled'],
                                                        color: ChartColors.color3,
                                                        associatedDataPoint: "cancel",
                                                        area: false
                                                    },
                                                ]}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                            ></Linechart>
                                        </div>
                                        <div className="col-4 marginTop-20 outer-border padding-10 ">
                                            <Piechart
                                                chartData={formatChartDataForPiechart(tooltipsDetailPowerformChartData.loadPowerFormChartData)}
                                                chartEntities={formatChartEntitiesForPiechart()}
                                                optionEntities={[
                                                    {
                                                        key: insightsLbls !== undefined && insightsLbls['submitted'],
                                                        color: ChartColors.color4,
                                                        associatedDataPoint: "submit",
                                                        area: false
                                                    },
                                                    {
                                                        key: insightsLbls !== undefined && insightsLbls['cancelled'],
                                                        color: ChartColors.color3,
                                                        associatedDataPoint: "cancel",
                                                        area: false
                                                    },
                                                ]}
                                                valueFormat={'value'}
                                                height={450}
                                                width={450}
                                                showLegends={true}
                                                chartType={'donut'}
                                                labelType={'percent'}
                                            />
                                        </div>
                                    </div>
                                    {tooltipsDetailPowerformChartData.warningFlag && (
                                        <div className="warningDiv">

                                            <p>{insightsLbls.chartLoadWarningMsg}
                                                <button
                                                    type="button"
                                                    className="closeWarning"
                                                    onClick={() =>
                                                        setTooltipsDetailPowerFormChartData((prevState: any) => ({
                                                            ...prevState,
                                                            warningFlag: false,
                                                        }))
                                                    }
                                                >
                                                    <span aria-hidden="true">
                                                        <Icon img="img/close-icon-large.svg" />
                                                    </span>
                                                    <span className="sr-only">Close</span>
                                                </button>
                                            </p>
                                        </div>
                                    )}
                                </>
                            )
                        ) : getErrorMessageTemplate(tooltipsDetailPowerformChartData.errorMsg)
                    ) : getLoaderTemplate()}
                </div>
            </>
        }
    }

    return (
        <section className="demo width100" ref={componentRef}>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['tooltips']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['tooltipDetails']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalyticsDetail"} disabled={true} />
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Tooltips Detail"}
                                widthDividedBy={10}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={tooltipTitle}>
            </UserInfoSection>

            <div id="tooltipsDetailSection">
                <div id="smartTipTableSection">
                    <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                            div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                {
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.summary,
                                            "tooltipText": insightsLbls.tooltipDetailSummaryInfo,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.tooltipTitle + ":",
                                                    "value": insightsLbls.tooltipDetailSummaryTitleInfo
                                                },
                                                {
                                                    "key": insightsLbls.interactions + ":",
                                                    "value": insightsLbls.tooltipDetailSummaryInteractionInfo
                                                },
                                                {
                                                    "key": insightsLbls.embeddedURLClicked + ":",
                                                    "value": insightsLbls.tooltipDetailSummaryEmbeddedUrlInfo
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>
                                }
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>
                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                {
                                    linkEventSummary.summaryFetched ? (
                                        errorCount.errorCount[5] === 0 ? (
                                            <div className="commonSummaryTable">
                                                <CommonTable tableId="2"
                                                    data={fetched_details.tooltipsDetails.linkEventSummary.data}
                                                    currentPage={linkEventSummary.page}
                                                    barColors=""
                                                    linkPresent={false}
                                                    showExport={false}
                                                    showSearch={true}
                                                    showEntries={true}
                                                    defaultSort={linkEventSummary.defaultSort}
                                                    tablePagination={linkEventSummary.tablePagination}
                                                    tableEvent={handleTableEvents}
                                                    firstTimeloadFlag={fetched_details.tooltipsDetails.linkEventSummary.firstTimeload}
                                                    hideDeleted={false}
                                                    checkboxText={insightsLbls.hideDeleted}
                                                    modalTable={false}
                                                />
                                            </div>
                                        )
                                            : errorCount.errorCount[5] === 1 ? (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            )
                                                : null)
                                        : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </CardContainer>
                    </div>
                </div>

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    {listData.tooltipsDataFetched ? (
                        errorCount.errorCount[4] === 0 ? (
                            listData.tooltipsData.length === 0 ? getNoRecordFoundTemplate() : (
                                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                    <div className="">
                                        {
                                            listData.tooltipsDataFetched ? (
                                                errorCount.errorCount[0] === 0 ? (
                                                    <div className="filterDiv row marginLR-0 displayBlock">
                                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                                            <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <SearchDropdown list={listData.tooltipsData} dispatchStatus={getStepStatus} source={"tooltipsDetail"}></SearchDropdown>
                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            {/* {localStorage.getItem('USER_ACTIVITY') === 'true' && (
                                                                <button type="button" id="userActivity" className="exportPdf-btn userActivityBtn" onClick={() => openUserAcitivtyModal()} data-html2canvas-ignore="true">
                                                                    <i className="fa fa-signal marginRight-5" aria-hidden="true"></i>
                                                                    <span className="width85">User Activity</span>
                                                                </button>
                                                            )} */}
                                                        </div>
                                                    </div>
                                                ) : getErrorMessageTemplate(listData.errorMsg)
                                            ) : getLoaderTemplate()
                                        }
                                    </div>
                                    <hr></hr>
                                    {
                                        getTemplate()
                                    }

                                </CardContainer>
                            )
                        ) : getErrorMessageTemplate(listData.errorMsg)
                    ) : getLoaderTemplate()
                    }
                </div>
            </div>


            {(tooltipsDetailLinkClickData.linkClickChartDataOptions?.length && tooltipsDetailLinkClickData.visibilityFlag) ? (
                <div id="tooltipsDetailSection">
                    <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                        {getLinkClickTemplate()}
                    </div>
                </div>
            ) : (<></>)}


            {userActivityModal.modalOpen && (localStorage.getItem('USER_ACTIVITY') === 'true')
                ? <UserActivity
                    closeModal={closeUserActivityModal}
                    guideId={tooltipDetail.tooltipID}
                    guideName={urlData['source']}
                    source={'tooltip'}></UserActivity>
                : null}

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}

        </section>
    );
}

export default withRouter(withReducer("tooltipsDetails", tooltipsDetailReducer)(TooltipsDetail));
