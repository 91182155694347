/*  
Real time data is available for following components (Only if real time flag is set to true from admin portal):- 
    Guide Insights
    Survey
    Automation Insights
    Organization
    Export
*/

import React, { useEffect, useState, useRef, useCallback } from "react";
import { Dropdown, ButtonGroup, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import withRouter from "hoc/withRouter";
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDates, setExportDates } from "../../store/Actions";
import { AppsState, DatesState, HandoffState, ExportDatesState, LanguageState } from "../../store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';
import "./Datepicker.scss";
import { DateSelection } from "utils/GlobalConstants";

import moment from "moment";


import { InsightLabels } from "labels";
import { add, differenceInDays, format, getYear, parseISO, sub, subDays } from "date-fns";

interface CurrentState {
    dates: DatesState;
    apps: AppsState;
    handoff: HandoffState
    exportDates: ExportDatesState;
    languageData: LanguageState
}

const Datepicker = (props: any) => {

    let f_date: any;
    let t_date: any;
    let seletcted_optn: any;
    let fdate_tdate_range: any;
    let exclude_flag: any;
    let excludeCheck: boolean;
    let max180Days: any;
    let max365Days: any;
    let calendarOptions: any = [
        { dateSelection: 3, text: "Last 7 Days", optn_disabled: false },
        { dateSelection: 10, text: "Last 30 Days", optn_disabled: false },
        { dateSelection: 16, text: "Last 60 Days", optn_disabled: false },
        { dateSelection: 11, text: "Last 90 Days", optn_disabled: false },
        { dateSelection: 12, text: "Last 180 Days", optn_disabled: false },
        { dateSelection: 13, text: "Last 365 Days", optn_disabled: false },
        { dateSelection: 14, text: "Last Calendar Year", optn_disabled: false },
        { dateSelection: 17, text: "YTD (Calendar Year)", optn_disabled: false },
        { dateSelection: 15, text: "Custom Date", optn_disabled: false },
    ];

    let dateDetails = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const excludeOption = useRef<HTMLInputElement>(null);
    const realTimeOption = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();

    /*-----State Declaration-----*/
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    //startDate, endDate is used for custom input calendar, this shows selected values in calendar
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    // const DateSelection.defaultDays: number | string = 30;
    // const DateSelection.defaultSelection: number | string = 10
    /*
        Not Required code

        const [datepickerFlag, setValue] = useState({
            closeDatepicker: true,
        })
        useEffect(() => {
            setValue({
                ...datepickerFlag,
                closeDatepicker: endDate === null,
            });
        }, [endDate, startDate]);

        const [prevMonth, setPrevMonth] = useState({
            showPrevMonth: true,
        });

        moment(new Date()).subtract(1, "days").toDate() -> subDays(new Date(), 1)

        const excludeWeekend_elem = document.getElementById('exclude_weekend') as HTMLInputElement;
        if (excludeWeekend_elem) {
            if (excludeWeekend_elem.checked) {
                setDateOptions((prevState: any) => {
                    return {
                        ...prevState,
                        excludeFlag: true,
                    }
                });

                localStorage.setItem('WEEKEND_TEXT', 'Exclude Weekends');
            } else {
                setDateOptions((prevState: any) => {
                    return {
                        ...prevState,
                        excludeFlag: false,
                    }
                });

                localStorage.setItem('WEEKEND_TEXT', 'Include Weekends');
            }
        }

    */

    const [maxDate, setMaxDate] = useState({
        max_date: subDays(new Date(), 1),
        maxDateSetFlag: false,
        maxDateFlag: false,
        min_date: ''
    });

    const [dropDown, setDropDown] = useState({
        isOpen: false,
    });

    const [calendarOptn, setCalendarOptn] = useState({
        calenderOptions: calendarOptions,
    });

    const [dateOptions, setDateOptions] = useState({
        dateSelection: seletcted_optn,
        today: format(new Date(), 'yyyy-MM-dd'),
        tdate: t_date,
        fdate: f_date,
        date_range: fdate_tdate_range,
        excludeFlag: exclude_flag ? true : false,
        datePickerDisabled: false,
        realTime_check: false
    });

    useEffect(() => {
        if (dateDetails.languageData.languageData) {
            setInsightsLbls(dateDetails.languageData.languageData);
        }
    }, [dateDetails.languageData.languageData])

    useEffect(() => {
        if (insightsLbls) {
            let caloptn = {
                ...calendarOptn,
            };
            caloptn.calenderOptions[0].text = insightsLbls.last7Days;
            caloptn.calenderOptions[1].text = insightsLbls.last30Days;
            caloptn.calenderOptions[2].text = insightsLbls.last60Days;
            caloptn.calenderOptions[3].text = insightsLbls.last90Days;
            caloptn.calenderOptions[4].text = insightsLbls.last180Days;
            caloptn.calenderOptions[5].text = insightsLbls.last365Days;
            caloptn.calenderOptions[6].text = insightsLbls.lastCalendarYear;
            caloptn.calenderOptions[7].text = insightsLbls.ytdCalendarYear;
            caloptn.calenderOptions[8].text = insightsLbls.customDate;

            setCalendarOptn((prevState: any) => {
                return {
                    ...prevState,
                    calendarOptions: caloptn
                }
            })
        }
    }, [insightsLbls])

    const useHasChanged = (val: any) => {
        const prevVal = usePrevious(val);
        if (prevVal !== undefined) return prevVal !== val;
        else return false;
    };

    const usePrevious = (value: any) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const setLocalStorageItems = (td, fd, so, ds, rtc) => {
        if (td) {
            localStorage.setItem("TDATE", td);
        }
        if (fd) {
            localStorage.setItem("FDATE", fd);
        }
        if (so) {
            localStorage.setItem("SELECTED_OPTN", so);
        }
        if (ds) {
            localStorage.setItem("DATEPICKER_SOURCE", ds);
        }
        if (rtc) {
            localStorage.setItem("REAL_TIME_CHECK", rtc);
        }

    }

    let hasVal1Changed = useHasChanged(dateDetails.apps.appCode);
    if (dateDetails.dates.fdate !== null && dateDetails.dates.tdate !== null && dateDetails.dates.dateSelection !== null) {
        f_date = dateDetails.dates.fdate;
        t_date = dateDetails.dates.tdate;
        seletcted_optn = dateDetails.dates.dateSelection;
        fdate_tdate_range = format(parseISO(dateDetails.dates.fdate), "dd MMM yyyy") + " to " + format(parseISO(dateDetails.dates.tdate), "dd MMM yyyy");
        exclude_flag = dateDetails.dates.excludeFlag;
    } else {
        // here 1
        f_date = format(sub(new Date(), { days: DateSelection.defaultDays }), "yyyy-MM-dd");
        t_date = format(sub(new Date(), { days: 1 }), "yyyy-MM-dd");
        seletcted_optn = 10;
        fdate_tdate_range = format(sub(new Date(), { days: DateSelection.defaultDays }), "dd MMM yyyy") + " to " + format(sub(new Date(), { days: 1 }), "dd MMM yyyy");
        exclude_flag = false;
        setLocalStorageItems(t_date, f_date, seletcted_optn, props.source, null);
        localStorage.setItem("WEEKEND_TEXT", exclude_flag ? insightsLbls.excludeWeekends : insightsLbls.includeWeekend);
        let dateValues: object = {
            tdate: t_date,
            fdate: f_date,
            dateSelection: DateSelection.defaultSelection,
            excludeFlag: exclude_flag ? true : false,
            source: props.source,
            url: location.pathname,
            realTimeCheck: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? true : false,
            realTime_tdate: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? format(add(t_date, { days: 1 }), "yyyy-MM-dd") : t_date,
            appId: dateDetails.apps.appId,
            appCode: dateDetails.apps.appCode

        };
        dispatch(setDates(dateValues));
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const dateValidation = () => {
        const isDashboardOrExport = location.pathname.includes("dashboard") || location.pathname.includes("export");
        const start_date = isDashboardOrExport
          ? dateDetails.apps.dashboardMinDate
          : dateDetails.apps.appStartDate;
        const end_date = isDashboardOrExport
          ? dateDetails.apps.dashboardMaxDate
          : dateDetails.apps.appEndDate;
      
        if (location.pathname.includes("export")) {
          const exportDateValues = {
            export_tdate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
            export_fdate: format(sub(new Date(), { days: DateSelection.defaultDays }), "yyyy-MM-dd"),
            dateSelection: DateSelection.defaultSelection,
          };
          dispatch(setExportDates(exportDateValues));
        }
      
        const dateRanges = {
          last7Days: [format(sub(new Date(), { days: 6 }), "yyyy-MM-dd")],
          last30Days: [format(sub(new Date(), { days: 29 }), "yyyy-MM-dd")],
          last60Days: [format(sub(new Date(), { days: 59 }), "yyyy-MM-dd")],
          last90Days: [format(sub(new Date(), { days: 90 }), "yyyy-MM-dd")],
          last180Days: [format(sub(new Date(), { days: 179 }), "yyyy-MM-dd")],
          last365Days: [format(sub(new Date(), { days: 364 }), "yyyy-MM-dd")],
          lastYear: [getYear(new Date()) - 1],
        };
      
        const caloptn = { ...calendarOptn };
      
        const optionsMap = [
          { range: dateRanges.last7Days, index: 0 },
          { range: dateRanges.last30Days, index: 1 },
          { range: dateRanges.last60Days, index: 2 },
          { range: dateRanges.last90Days, index: 3 },
          { range: dateRanges.last180Days, index: 4 },
          { range: dateRanges.last365Days, index: 5 },
          { range: dateRanges.lastYear, index: 6 },
        ];
      
        optionsMap.forEach(({ range, index }) => {
          caloptn.calenderOptions[index].optn_disabled = validPeriod(
            start_date,
            end_date,
            range,
            0
          );
        });
      
        setCalendarOptn(caloptn);
      };
      

    const validPeriod = (
        startDate: any,
        endDate: any,
        bookings: any,
        flag: number
    ) => {
        try {
            var valid = true;

            for (var i = 0; i < bookings.length; i++) {
                var date = bookings[i];
                if (startDate <= date && date <= endDate) {
                    valid = false;
                    break;
                }
            }
            return valid;
        } catch (error) {
            showBoundary(error)
        }
    };

    const onChange = (dates: any) => {
        try {
          const [start, end] = dates;
      
          // Update start and end dates
          setStartDate(start);
          setEndDate(format(new Date(start), "yyyy-MM-dd") === format(new Date(end), "yyyy-MM-dd") ? null : end);
      
          // Calculate max dates
          const max180Days = format(add(start, { days: 180 }), "yyyy-MM-dd");
          const max365Days = format(add(start, { days: 365 }), "yyyy-MM-dd");
      
          // Determine flags
          let setFlag = props.source === "exportEmail" 
            ? false 
            : format(new Date(), "yyyy-MM-dd") > max180Days && !end;
      
          // Set max date object based on conditions
          const getMaxDate = () => {
            const comparisonDate = props.source === "exportEmail" ? max365Days : max180Days;
            return format(new Date(), "yyyy-MM-dd") > comparisonDate
              ? moment(comparisonDate).toDate()
              : moment(new Date().setDate(new Date().getDate() - 1)).toDate();
          };
      
          if (!end) {
            setMaxDate({
              ...maxDate,
              max_date: getMaxDate(),
              maxDateSetFlag: setFlag,
              maxDateFlag: true,
            });
          } else {
            setMaxDate((prevState: any) => ({
              ...prevState,
              maxDateFlag: false,
              maxDateSetFlag: setFlag,
            }));
          }
        } catch (error) {
          showBoundary(error);
        }
      };
      

    const ExampleCustomInput = ({ value, onClick }: any) =>
    (
        <button
            className="customDateBtn btn"
            onClick={() => {
                onClick();
            }}>
            {insightsLbls.selectRange}
        </button>
    );

    /*
        Not required code
        const setPreviousMonth = () => {
            try {
                setPrevMonth({
                    ...prevMonth,
                    showPrevMonth: true,
                });
            } catch (error) {
                showBoundary(error)
            }
        };
    */

    const changeCalendarSelection = (optn: number) => {
        try {
            setDateOptions((prevState: any) => {
                return {
                    ...prevState,
                    dateSelection: optn,
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const applyDate = (optn: number, buttonEvent?: any) => {
        try {
          // Helper to parse URL parameters
          const getUrlData = () => {
            const urlData: any = {};
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
              urlData[k] = v;
            });
            return urlData;
          };
      
          // Helper to set date options
          const updateDateOptions = (options: any) => {
            setDateOptions((prevState: any) => ({
              ...prevState,
              ...options,
            }));
          };
      
          // Handle Exclude Weekends Flag
          const excludeFlag = excludeOption?.current?.checked || false;
          updateDateOptions({ excludeFlag });
          localStorage.setItem("WEEKEND_TEXT", excludeFlag ? "Exclude Weekends" : "Include Weekends");
      
          const handleCustomDate = () => {
            if (!endDate) {
              const urlData = getUrlData();
              const toDate = urlData["tdate"] || localStorage.getItem("TDATE");
              const fromDate = urlData["fdate"] || localStorage.getItem("FDATE");
      
              setStartDate(new Date(fromDate));
              setEndDate(new Date(toDate));
      
              updateDateOptions({
                tdate: toDate,
                fdate: fromDate,
                dateSelection: 15,
                date_range: `${format(parseISO(fromDate), "dd MMM yyyy")} to ${format(parseISO(toDate), "dd MMM yyyy")}`,
              });
      
              const dateValues = {
                tdate: toDate,
                fdate: fromDate,
                dateSelection: 15,
                source: props.source,
                url: location.pathname,
                realTimeCheck: false,
                excludeFlag,
                appId: dateDetails.apps.appId,
                appCode: dateDetails.apps.appCode,
                realTime_tdate: toDate,

              };

              dispatch(setDates(dateValues));
              return;
            }
      
            const toDate = format(endDate, "yyyy-MM-dd");
            const fromDate = format(startDate, "yyyy-MM-dd");
      
            updateDateOptions({
              tdate: toDate,
              fdate: fromDate,
              dateSelection: 15,
              date_range: `${format(startDate, "dd MMM yyyy")} to ${format(endDate, "dd MMM yyyy")}`,
            });
      
            setLocalStorageItems(toDate, fromDate, "15", props.source, "false");
      
            const dateValues = {
              tdate: toDate,
              fdate: fromDate,
              dateSelection: 15,
              source: props.source,
              url: location.pathname,
              realTimeCheck: false,
              excludeFlag,
              appId: dateDetails.apps.appId,
              appCode: dateDetails.apps.appCode,
              realTime_tdate: toDate,

            };
      
            dispatch(setDates(dateValues));
          };
      
          const handleCalendarSelection = (days: number, otherArgs?: any) => {
            calendarSelection(days, 0, false, -1, false, optn, excludeCheck, buttonEvent);
          };
      
          // Switch cases
          switch (optn) {
            case 3:
              handleCalendarSelection(6);
              break;
            case 10:
              handleCalendarSelection(29);
              break;
            case 16:
              handleCalendarSelection(59);
              break;
            case 11:
              handleCalendarSelection(89);
              break;
            case 12:
              handleCalendarSelection(179);
              break;
            case 13:
              location.pathname.includes("org-detail") || location.pathname.includes("dashboard")
                ? get90DaysCalculation()
                : handleCalendarSelection(364);
              break;
            case 14:
              location.pathname.includes("org-detail") || location.pathname.includes("dashboard")
                ? get90DaysCalculation()
                : selectlyear(excludeCheck, buttonEvent);
              break;
            case 15:
              location.pathname.includes("org-detail") || location.pathname.includes("dashboard")
                ? get90DaysCalculation()
                : handleCustomDate();
              break;
            case 17:
              location.pathname.includes("org-detail") || location.pathname.includes("dashboard")
                ? get90DaysCalculation()
                : selectYTD(excludeCheck, buttonEvent);
              break;
            default:
              console.warn(`Unhandled option: ${optn}`);
          }
      
          // Close dropdown and reset max date flag
          setDropDown({ ...dropDown, isOpen: false });
          setMaxDate((prevState: any) => ({ ...prevState, maxDateSetFlag: false }));
        } catch (error) {
          console.error("Error applying date:", error);
          showBoundary(error);
        }
      };
      

    const get90DaysCalculation = () => {
        let dateValues: object = {
            tdate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
            fdate: format(sub(new Date(), { days: 90 }), "yyyy-MM-dd"),
            dateSelection: dateDetails.dates.dateSelection,
            excludeFlag: exclude_flag ? true : false,
            source: 'component',
            url: location.pathname,
            realTimeCheck: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? true : false,
            realTime_tdate: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? format(add(t_date, { days: 1 }), "yyyy-MM-dd") : t_date,
            appId: dateDetails.apps.appId,
            appCode: dateDetails.apps.appCode,
        };
        setDateOptions((prevState: any) => {
            return {
                ...prevState,
                tdate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
                fdate: format(sub(new Date(), { days: 90 }), "yyyy-MM-dd"),
                dateSelection: dateDetails.dates.dateSelection,
                date_range: format(sub(new Date(), { days: 90 }), "dd MMM yyyy") +
                    " to " +
                    format(sub(new Date(), { days: 1 }), "dd MMM yyyy"),
            }
        });

        dispatch(setDates(dateValues));
    }

    const calendarSelection = useCallback((
        fdayCount: any,
        weeks: number,
        singleDay: boolean,
        result: number,
        call_flag: boolean,
        storeOptn: any,
        excludeCheck: boolean,
        buttonEvent: any
    ) => {
        try {
            if (location.pathname.includes('org-view')) {
                fdayCount = 6;
            } else if (location.pathname.includes('org-detail') || location.pathname.includes('dashboard')) {
                fdayCount = 89;
            } 

            const realTime_elem = document.getElementById('realTime_data') as HTMLInputElement;
            if (realTime_elem) {
                if (realTime_elem.checked) {
                    setDateOptions((prevState: any) => {
                        return {
                            ...prevState,
                            realTime_check: true,
                        }
                    });
                    localStorage.setItem('REAL_TIME_CHECK', 'true');
                } else {
                    setDateOptions((prevState: any) => {
                        return {
                            ...prevState,
                            realTime_check: false,
                        }
                    });
                    localStorage.setItem('REAL_TIME_CHECK', 'false');
                }
            }

            const prevFlagVal: number = 1;
            setDateOptions((prevState: any) => {
                return {
                    ...prevState,
                    tdate: format(sub(new Date(), { days: prevFlagVal }), "yyyy-MM-dd"),
                    fdate: format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "yyyy-MM-dd"),
                    dateSelection: storeOptn,
                    date_range: format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "dd MMM yyyy") +
                        " to " +
                        format(sub(new Date(), { days: prevFlagVal }), "dd MMM yyyy"),
                    excludeFlag: excludeCheck ? true : false,
                }
            });

            setLocalStorageItems(
                format(sub(new Date(), { days: prevFlagVal }), "yyyy-MM-dd"),
                format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "yyyy-MM-dd"),
                storeOptn, props.source, null);




            const tdate_val = format(sub(new Date(), { days: prevFlagVal }), "yyyy-MM-dd");

            let urlData: any = {};

            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            let dateValues: object = {
                tdate: tdate_val,
                fdate: format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "yyyy-MM-dd"),
                dateSelection: storeOptn,
                excludeFlag: localStorage.getItem("WEEKEND_TEXT") === 'Exclude Weekends' ? true : false,
                source: props.source,
                url: location.pathname,
                realTimeCheck: localStorage.getItem('REAL_TIME_CHECK') === 'true' ? true : false,
                realTime_tdate: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? format(add(parseISO(tdate_val), { days: 1 }), "yyyy-MM-dd") : tdate_val,
                appId: dateDetails.apps.appId,
                appCode: dateDetails.apps.appCode
            };

            if ((dateDetails.apps.appStartDate && dateDetails.apps.appEndDate) || location.pathname.includes('dashboard') || location.pathname.includes('organization')) {

                if (!dateDetails.handoff.setHandoff) {
                    if (!buttonEvent) {
                        let urlData: any = {};

                        const query = new URLSearchParams(location.search);
                        query.forEach((v, k) => {
                            urlData[k] = v;
                        });

                        if (urlData['tdate'] && urlData['fdate']) {
                            let tdate_value = urlData['tdate'];
                            let fdate_value = urlData['fdate'];

                            if (moment(fdate_value) <= moment(new Date()).subtract(729, "days")) {
                                tdate_value = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
                                fdate_value = moment(new Date()).subtract(DateSelection.defaultDays - 1 + prevFlagVal, "days").format("YYYY-MM-DD");
                            }

                            if (format(new Date(), 'yyyy-MM-dd') == tdate_value) {
                                tdate_value = format(sub(new Date(), { days: 1 }), "yyyy-MM-dd");
                            }

                            const a = parseISO(tdate_value);
                            const b = parseISO(fdate_value);
                            const dayDiffCount = differenceInDays(a, b) + 1;

                            let optnVal = DateSelection.defaultSelection;
                            let validDateRange = 1;

                            if (tdate_value < moment(new Date()).subtract(1, "days").format("YYYY-MM-DD") && moment(fdate_value).format("YYYY-MM-DD") < tdate_value) {
                                setDateOptions((prevState: any) => {
                                    return {
                                        ...prevState,
                                        tdate: moment(tdate_value).format("YYYY-MM-DD"),
                                        fdate: moment(fdate_value).format("YYYY-MM-DD"),
                                        dateSelection: 15,
                                        date_range: moment(fdate_value).format("DD MMM YYYY") +
                                            " to " +
                                            moment(tdate_value).format("DD MMM YYYY"),
                                    }
                                });

                                setLocalStorageItems(
                                    moment(tdate_value).format("YYYY-MM-DD"),
                                    moment(fdate_value).format("YYYY-MM-DD"),
                                    '15', null, null);

                                dateValues = {
                                    ...dateValues,
                                    tdate: moment(tdate_value).format("YYYY-MM-DD"),
                                    fdate: moment(fdate_value).format("YYYY-MM-DD"),
                                    realTime_tdate: moment(tdate_value).format("YYYY-MM-DD"),
                                    dateSelection: 15,
                                    appId: dateDetails.apps.appId,
                                    appCode: dateDetails.apps.appCode,
                                    url: location.pathname,

                                };
                            } else if (tdate_value > moment(new Date()).subtract(1, "days").format("YYYY-MM-DD") || tdate_value < moment(fdate_value).format("YYYY-MM-DD") || moment(fdate_value).format("YYYY-MM-DD") > tdate_value) {
                                setDateOptions((prevState: any) => {
                                    return {
                                        ...prevState,
                                        tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                                        fdate: moment(new Date()).subtract(DateSelection.defaultDays + prevFlagVal, "days").format("YYYY-MM-DD"),
                                        dateSelection: DateSelection.defaultSelection,
                                        date_range: moment(new Date()).subtract(DateSelection.defaultDays + prevFlagVal, "days").format("DD MMM YYYY") +
                                            " to " +
                                            moment(new Date()).subtract(1, "days").format("DD MMM YYYY"),
                                    }
                                });

                                setLocalStorageItems(
                                    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                                    moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("YYYY-MM-DD"),
                                    '10', null, null);

                                dateValues = {
                                    ...dateValues,
                                    tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                                    fdate: moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("YYYY-MM-DD"),
                                    appId: dateDetails.apps.appId,
                                    appCode: dateDetails.apps.appCode,
                                    url: location.pathname,
                                };
                            }
                            else {
                                switch (dayDiffCount) {
                                    case 7:
                                        dateValues = { ...dateValues, dateSelection: 3 };
                                        localStorage.setItem("SELECTED_OPTN", '3');
                                        optnVal = 3;
                                        validDateRange = 1;
                                        break;

                                    case 30:
                                        dateValues = { ...dateValues, dateSelection: 10 };
                                        localStorage.setItem("SELECTED_OPTN", '10');
                                        optnVal = 10;
                                        validDateRange = 1;
                                        break;

                                    case 60:
                                        dateValues = { ...dateValues, dateSelection: 16 };
                                        localStorage.setItem("SELECTED_OPTN", '16');
                                        optnVal = 16;
                                        validDateRange = 1;
                                        break;

                                    case 90:
                                        dateValues = { ...dateValues, dateSelection: 11 };
                                        localStorage.setItem("SELECTED_OPTN", '11');
                                        optnVal = 11;
                                        validDateRange = 1;
                                        break;

                                    case 180:
                                        dateValues = { ...dateValues, dateSelection: 12 };
                                        localStorage.setItem("SELECTED_OPTN", '12');
                                        optnVal = 12;
                                        validDateRange = 1;
                                        break;

                                    case 365:
                                        dateValues = { ...dateValues, dateSelection: 13 };
                                        localStorage.setItem("SELECTED_OPTN", '13');
                                        optnVal = 13;
                                        validDateRange = 1;
                                        break;

                                    default:
                                        const a = parseISO(tdate_value);
                                        const b = parseISO(fdate_value);
                                        const dayDiffCount = differenceInDays(a, b)

                                        let currentYear = getYear(new Date());

                                        const ytd_a = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");
                                        const ytd_b = moment(new Date(currentYear + "-01-01")).format("YYYY-MM-DD");



                                        if (ytd_b === fdate_value && ytd_a === tdate_value) {
                                            dateValues = { ...dateValues, dateSelection: 17 };
                                            localStorage.setItem("SELECTED_OPTN", '17');
                                            optnVal = 127;
                                            validDateRange = 1;
                                        } else {
                                            validDateRange = dayDiffCount > 90 ? 0 : 1;
                                            optnVal = dayDiffCount > 90 ? DateSelection.defaultSelection : 15;
                                            localStorage.setItem("SELECTED_OPTN", dayDiffCount > 90 ? DateSelection.defaultSelection.toString() : '15');
                                            dateValues = { ...dateValues, dateSelection: dayDiffCount > 90 ? DateSelection.defaultSelection : 15 };
                                        }
                                        break;
                                }

                                if (validDateRange) {
                                    setDateOptions((prevState: any) => {
                                        return {
                                            ...prevState,
                                            tdate: format(parseISO(tdate_value), "yyyy-MM-dd"),
                                            fdate: format(parseISO(fdate_value), "yyyy-MM-dd"),
                                            dateSelection: optnVal,
                                            date_range: format(parseISO(fdate_value), "dd MMM yyyy") +
                                                " to " +
                                                format(parseISO(tdate_value), "dd MMM yyyy"),
                                        }
                                    });

                                    setLocalStorageItems(format(
                                        parseISO(tdate_value), "yyyy-MM-dd"),
                                        format(parseISO(fdate_value), "yyyy-MM-dd"),
                                        optnVal.toString(), null, null);

                                    dateValues = {
                                        ...dateValues,
                                        tdate: format(parseISO(tdate_value), "yyyy-MM-dd"),
                                        fdate: format(parseISO(fdate_value), "yyyy-MM-dd"),
                                        appId: dateDetails.apps.appId,
                                        appCode: dateDetails.apps.appCode,
                                        url: location.pathname,
                                        source: props.source,
                                    };
                                } else {
                                    setDateOptions((prevState: any) => {
                                        return {
                                            ...prevState,
                                            tdate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
                                            fdate: format(sub(new Date(), { days: ((DateSelection.defaultDays - 1) + prevFlagVal) }), "yyyy-MM-dd"),
                                            dateSelection: 3,
                                            date_range: format(sub(new Date(), { days: (DateSelection.defaultSelection + prevFlagVal) }), "dd MMM yyyy") +
                                                " to " +
                                                format(sub(new Date(), { days: 1 }), "dd MMM yyyy"),
                                        }
                                    });

                                    setLocalStorageItems(format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
                                        format(sub(new Date(), { days: ((DateSelection.defaultDays - 1) + prevFlagVal) }), "yyyy-MM-dd"),
                                        DateSelection.defaultSelection.toString(), null, null);

                                    dateValues = {
                                        ...dateValues,
                                        tdate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
                                        fdate: format(sub(new Date(), { days: ((DateSelection.defaultDays - 1) + prevFlagVal) }), "yyyy-MM-dd"),
                                        appId: dateDetails.apps.appId,
                                        appCode: dateDetails.apps.appCode,
                                        url: location.pathname,
                                        source: props.source,
                                    };
                                }
                            }
                        }
                    }

                    if (props.source !== 'exportEmail') {
                        if(!location.pathname.includes('dashboard')){
                            dispatch(setDates(dateValues));
                        }
                    }
                    else {
                        setDateOptions((prevState: any) => {
                            return {
                                ...prevState,
                                tdate: format(sub(new Date(), { days: prevFlagVal }), "yyyy-MM-dd"),
                                fdate: format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "yyyy-MM-dd"),
                                dateSelection: storeOptn,
                                date_range: format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "dd MMM yyyy") +
                                    " to " +
                                    format(sub(new Date(), { days: prevFlagVal }), "dd MMM yyyy"),
                                excludeFlag: excludeCheck ? true : false,
                            }
                        });

                        const exportDateValues = {
                            export_tdate: format(sub(new Date(), { days: prevFlagVal }), "yyyy-MM-dd"),
                            export_fdate: format(sub(new Date(), { days: (fdayCount + prevFlagVal) }), "yyyy-MM-dd"),
                            dateSelection: storeOptn,
                        };
                        dispatch(setExportDates(exportDateValues));
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [dateDetails.apps]);

    const selectlyear = (excludeCheck: boolean, buttonEvent: any) => {
        try {
            let lastYear = getYear(new Date()) - 1;
            setDateOptions((prevState: any) => {
                return {
                    ...prevState,
                    tdate: lastYear + "-12-31",
                    fdate: lastYear + "-01-01",
                    dateSelection: 14,
                    date_range: "01 Jan " + lastYear + " to 31 Dec " + lastYear,
                    excludeFlag: localStorage.getItem("WEEKEND_TEXT") === 'Exclude Weekends' ? true : false,
                }
            });

            setLocalStorageItems(lastYear + "-12-31", lastYear + "-01-01", "14", props.source, 'false');
            let dateValues: object = {
                tdate: lastYear + "-12-31",
                fdate: lastYear + "-01-01",
                dateSelection: 14,
                excludeFlag: localStorage.getItem("WEEKEND_TEXT") === 'Exclude Weekends' ? true : false,
                source: props.source,
                url: location.pathname,
                realTimeCheck: false,
                realTime_tdate: lastYear + "-12-31",
                appId: dateDetails.apps.appId,
                appCode: dateDetails.apps.appCode
            };

            if (location.pathname.includes("export")) {
                const exportDateValues = {
                    export_fdate: lastYear + "-01-01",
                    export_tdate: lastYear + "-12-31",
                    dateSelection: 14,
                }
                dispatch(setExportDates(exportDateValues));
            }
            // To prevent buttonEvent on route change
            // if (buttonEvent) {
            dispatch(setDates(dateValues));
            // }
        } catch (error) {
            showBoundary(error)
        }
    };


    const selectYTD = (excludeCheck: boolean, buttonEvent: any) => {
        try {
            const prevFlagVal: number = 1;

            let currentYear = getYear(new Date());
            let fdate = format(sub(new Date(), { days: prevFlagVal }), "yyyy-MM-dd");


            setDateOptions((prevState: any) => {
                return {
                    ...prevState,
                    tdate: fdate,
                    fdate: currentYear + "-01-01",
                    dateSelection: 17,
                    date_range: format(new Date(currentYear + "-01-01"), "dd MMM yyyy") +
                        " to " +
                        format((new Date(fdate)), "dd MMM yyyy"),
                    excludeFlag: localStorage.getItem("WEEKEND_TEXT") === 'Exclude Weekends' ? true : false,
                }
            });




            setLocalStorageItems(currentYear + "-01-01", fdate, "17", props.source, 'false');
            let dateValues: object = {
                tdate: fdate,
                fdate: currentYear + "-01-01",
                dateSelection: 17,
                excludeFlag: localStorage.getItem("WEEKEND_TEXT") === 'Exclude Weekends' ? true : false,
                source: props.source,
                url: location.pathname,
                realTimeCheck: false,
                realTime_tdate: fdate,
                appId: dateDetails.apps.appId,
                appCode: dateDetails.apps.appCode
            };

            if (location.pathname.includes("export")) {
                const exportDateValues = {
                    export_fdate: currentYear + "-01-01",
                    export_tdate: fdate,
                    dateSelection: 17,
                }
                dispatch(setExportDates(exportDateValues));
            }
            dispatch(setDates(dateValues));
        } catch (error) {
            showBoundary(error)
        }
    };


    const getDates = () => { };

    const toggleDropDown = (ev: any) => {
        try {
            setDropDown({
                ...dropDown,
                isOpen: ev,
            });

            setMaxDate((prevState: any) => {
                return {
                    ...prevState,
                    maxDateFlag: false
                }
            })

            setTimeout(() => {
                if (excludeOption.current !== undefined && excludeOption.current !== null) {
                    excludeOption.current.checked = localStorage.getItem('WEEKEND_TEXT') === 'Include Weekends' ? false : true;
                }

                const elem = document.getElementById('realTime_data') as HTMLInputElement;
                if (elem) {
                    elem.checked = localStorage.getItem('REAL_TIME_CHECK') === 'true' ? true : false
                }

                const exclude_elem = document.getElementById('exclude_weekend') as HTMLInputElement;
                if (exclude_elem) {
                    exclude_elem.checked = localStorage.getItem('WEEKEND_TEXT') === 'Include Weekends' ? false : true;
                }
            }, 100);

            if (props.source !== 'exportEmail') {
                if (ev) {
                    let switchOptn: number;
                    switchOptn = +dateDetails.dates.dateSelection;

                    switch (switchOptn) {
                        case 3:
                            if (calendarOptn.calenderOptions[0].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 10:
                            if (calendarOptn.calenderOptions[1].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 16:
                            if (calendarOptn.calenderOptions[2].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 11:
                            if (calendarOptn.calenderOptions[3].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 12:
                            if (calendarOptn.calenderOptions[4].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 13:
                            if (calendarOptn.calenderOptions[5].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 14:
                            if (calendarOptn.calenderOptions[6].optn_disabled === true) {
                                switchOptn = 15;
                            }
                            break;

                        case 15:
                            switchOptn = 15;
                            break;

                        case 17:
                            switchOptn = 17;
                            break;

                        default:
                            switchOptn = 15;
                            break;
                    }
                    setDateOptions((prevState: any) => {
                        return {
                            ...prevState,
                            dateSelection: switchOptn,
                            excludeFlag: dateDetails.dates.excludeFlag ? true : false,
                        }
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Change date if real time enable is set
    useEffect(() => {
        try {
            setMaxDate((prevState: any) => {
                return {
                    ...prevState,
                    max_date: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
                    maxDateFlag: true
                }
            })

            if (location.pathname.includes('org-detail') || location.pathname.includes('dashboard')) {
                get90DaysCalculation();
            }

            if (location.pathname.includes('org-view')) {
                const prevFlagVal = 1;
                setDateOptions((prevState: any) => {
                    return {
                        ...prevState,
                        tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                        fdate: moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("YYYY-MM-DD"),
                        dateSelection: DateSelection.defaultSelection,
                        date_range: moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("DD MMM YYYY") +
                            " to " +
                            moment(new Date()).subtract(1, "days").format("DD MMM YYYY"),
                    }
                });

                let dateValues: object = {
                    tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                    fdate: moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("YYYY-MM-DD"),
                    dateSelection: DateSelection.defaultSelection,
                    excludeFlag: exclude_flag ? true : false,
                    source: 'component',
                    url: location.pathname,
                    realTimeCheck: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? true : false,
                    realTime_tdate: localStorage.getItem("REAL_TIME_CHECK") === 'true' ? format(add(t_date, { days: 1 }), "yyyy-MM-dd") : t_date,
                    appId: dateDetails.apps.appId,
                    appCode: dateDetails.apps.appCode
                };
                dispatch(setDates(dateValues));
            }

            if (location.pathname.includes("export")) {
                setDateOptions((prevState: any) => {
                    return {
                        ...prevState,
                        tdate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
                        fdate: format(sub(new Date(), { days: DateSelection.defaultDays }), "yyyy-MM-dd"),
                        dateSelection: DateSelection.defaultSelection,
                        date_range: format(sub(new Date(), { days: DateSelection.defaultDays }), "dd MMM yyyy") +
                            " to " +
                            format(sub(new Date(), { days: 1 }), "dd MMM yyyy"),
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [location.pathname, props.dateDisabled])



    // calculate dates
    useEffect(() => {
        try {
            if (hasVal1Changed) {
                let checkEndDate: any;
                if (dateDetails.apps.appEndDate !== null)
                    checkEndDate = dateDetails.apps.appEndDate.split(" ")[0];
                if (format(sub(parseISO(checkEndDate), { days: 1 }), "yyyy-MM-dd") !== format(sub(new Date(), { days: 1 }), "yyyy-MM-dd")) {
                    let end: any = format(sub(parseISO(checkEndDate), { days: 0 }), "yyyy-MM-dd");
                    let start: any = format(sub(parseISO(checkEndDate), { days: DateSelection.defaultDays - 1 }), "yyyy-MM-dd");
                    setStartDate(start);
                    setEndDate(end);
                    applyDate(15);
                } else {
                    applyDate(DateSelection.defaultSelection);
                }
            } else {
                // setTimeout(() => {
                if (excludeOption.current !== undefined && excludeOption.current !== null) {
                    excludeOption.current.checked = localStorage.getItem('WEEKEND_TEXT') === 'Include Weekends' ? false : true;
                }

                // if (realTimeOption.current !== undefined && realTimeOption.current !== null) {
                //     realTimeOption.current.checked = localStorage.getItem('REAL_TIME_CHECK') === 'false' ? false : true;
                // }

                if (props.source === 'modal' || props.source === 'exportEmail') {
                    if (dateOptions.dateSelection === 12 || dateOptions.dateSelection === 13 || dateOptions.dateSelection === 14) {
                        for (let item of calendarOptn.calenderOptions) {
                            if (item.optn_disabled === false) {
                                applyDate(item.dateSelection)
                                break;
                            }
                        }
                    }
                } else if (props.source === 'component') {
                    let urlData: any = {};
                    const query = new URLSearchParams(location.search);
                    query.forEach((v, k) => {
                        urlData[k] = v;
                    });


                    // changed to resolved multiple calls on component switch
                    if (urlData['app_id']) {
                        if (dateDetails.apps.appId == urlData['app_id']) {
                            applyDate(dateDetails.dates.dateSelection);
                        }
                    } else if (location.pathname.includes('dashboard')) {
                        applyDate(dateDetails.dates.dateSelection);
                    }

                }
            }

            if (dateDetails.apps.appStartDate !== null && dateDetails.apps.appEndDate !== null) {
                dateValidation();
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [dateDetails.apps]);

    // Disable calender option for detail page
    useEffect(() => {
        setDateOptions((prevState: any) => {
            return {
                ...prevState,
                datePickerDisabled: location.pathname.includes("detail") || props.dateDisabled || location.pathname.includes("organization") || location.pathname.includes("dashboard"),
            }
        });


        if (location.pathname.includes('org-view')) {
            const prevFlagVal = 1;
            setDateOptions((prevState: any) => {
                return {
                    ...prevState,
                    tdate: moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"),
                    fdate: moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("YYYY-MM-DD"),
                    dateSelection: DateSelection.defaultSelection,
                    date_range: moment(new Date()).subtract((DateSelection.defaultDays - 1) + prevFlagVal, "days").format("DD MMM YYYY") +
                        " to " +
                        moment(new Date()).subtract(1, "days").format("DD MMM YYYY"),
                }
            });

        }
    }, [location.pathname, props.dateDisabled]);

    useEffect(() => {
        try {
            if (!dropDown.isOpen) {
                setMaxDate((prevState: any) => {
                    return {
                        ...prevState,
                        maxDateSetFlag: false
                    }
                })
            }

        } catch (error) {
            showBoundary(error)
        }
    }, [dropDown.isOpen])

    const getMinDate = () => {
        try {
            const minDate = moment(new Date()).subtract(729, "days").format("YYYY-MM-DD");
            if (location.pathname.includes("dashboard") || location.pathname.includes("export")) {
                if (moment(dateDetails?.apps?.dashboardMinDate) <= moment(new Date()).subtract(729, "days")) {
                    return new Date(minDate).setDate(new Date(minDate).getDate())
                } else {
                    return new Date(dateDetails?.apps?.dashboardMinDate).setDate(new Date(dateDetails?.apps?.dashboardMinDate).getDate())
                }
            } else {
                if (moment(dateDetails?.apps?.appStartDate) <= moment(new Date()).subtract(729, "days")) {
                    return new Date(minDate).setDate(new Date(minDate).getDate())
                } else {
                    return new Date(dateDetails?.apps?.appStartDate).setDate(new Date(dateDetails?.apps?.appStartDate).getDate())
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }


    return (
        <div className={props.width100 ? "width-100": ''}>
            <div className="dateRange-title"  style={{ visibility: location.pathname.includes('goal') ? 'hidden' : 'visible' }}>
                {insightsLbls.dateRange}
            </div>
            <div className={"datepiecker-div floatRight " + (props.source === "modal" || props.source === "exportEmail" ? ("datepiecker-div-modal") : (""))}
                style={{ visibility: location.pathname.includes('goal') ? 'hidden' : 'visible' }}>
                <Dropdown
                    as={ButtonGroup}
                    id="dropdown-custom-1"
                    show={dropDown.isOpen}
                    onToggle={(ev) => toggleDropDown(ev)}
                >
                    <Dropdown.Toggle disabled={(props.source === "modal" || props.source === "exportEmail") ? false : dateOptions.datePickerDisabled}>
                        <div className="displayFlex alignCenter">
                            <div className="width100">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    className="paddingTopBottom date-input"
                                    value={(props.source === "exportEmail" && props.dateRange) ? props.dateRange :
                                        (props.source === "taskListDetail" ? fdate_tdate_range : dateOptions.date_range)}
                                    readOnly
                                />
                            </div>

                            {dropDown.isOpen
                                ? (<i
                                    aria-hidden="true"
                                    className="dropdown-toggle fa fa-angle-up  switchApp-btn-icon"
                                ></i>)
                                : (
                                    <i
                                        aria-hidden="true"
                                        className="dropdown-toggle fa fa-angle-down switchApp-btn-icon"
                                    ></i>
                                )
                            }

                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={"marginTop-42"}>
                        <div>
                            <ul className="padding0 marginBottom-15">
                                {calendarOptn.calenderOptions.map(function (item: any, key: any) {
                                    return (
                                        <div className={`displayFlex calenderOptionspadding alignCenter ${item.dateSelection === 14 && 'lastYearOptn'}`} key={key}>
                                            {item.dateSelection === 14 && (<p className="realTime-warning">{insightsLbls.realTimeDataNotAvailable}</p>)}
                                            <ToggleButtonGroup
                                                className="datepickerRadioButtons"
                                                name="dateOptions"
                                                type="radio"
                                                value={dateOptions.dateSelection}
                                                onClick={() => changeCalendarSelection(item.dateSelection)}
                                            >
                                                <ToggleButton
                                                    id={"tbtn_" + key}
                                                    style={{ cursor: item.optn_disabled ? 'default' : 'pointer' }}
                                                    disabled={item.optn_disabled}
                                                    className="datepickerRadio"
                                                    value={item.dateSelection}> {dateOptions.dateSelection === item.dateSelection ? (<span className="dot">&nbsp;</span>) : (null)}
                                                </ToggleButton>
                                            </ToggleButtonGroup>

                                            <span
                                                className={`${item.optn_disabled
                                                    ? "datepicker-optn fontGrey"
                                                    : "datepicker-optn"
                                                    } ${+dateOptions.dateSelection === item.dateSelection
                                                        ? "font700"
                                                        : ""
                                                    }`}
                                            >
                                                {item.text}

                                            </span>
                                            {/* {item.dateSelection == 13 && (<span className="marginLeft-10"> ({insightsLbls.ytd})</span>)} */}
                                            {item.text === insightsLbls.customDate &&
                                                dateOptions.dateSelection === 15 ? (
                                                <DatePicker
                                                    calendarClassName={"datePickerClassName " + (props.source === "modal" || props.source === "exportEmail" ? ("datePickerClassNameExportModal") : (""))}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsStart
                                                    selectsRange
                                                    monthsShown={props.source === "exportEmail" ? 1 : 2}
                                                    maxDate={maxDate.maxDateFlag
                                                        ? maxDate.max_date
                                                        : (new Date().setDate(new Date().getDate() - 1))}
                                                    minDate={getMinDate()}
                                                    shouldCloseOnSelect={false}
                                                    customInput={<ExampleCustomInput />}
                                                    showPreviousMonths={false} //prevMonth.showPrevMonth
                                                    onSelect={getDates}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    yearDropdownItemNumber={1}
                                                />
                                            ) : null}
                                        </div>
                                    );
                                })}
                            </ul>

                            <div className="exclude-apply-div">
                                <div className="col-12">
                                    <div className="datePicker-optns">
                                        <label className="custom-check">
                                            <input
                                                type="checkbox"
                                                ref={realTimeOption}
                                                disabled={(location.pathname.includes('app-insights') || location.pathname.includes('dashboard') || location.pathname.includes('organization') || props.source === 'modal' || props.source === 'exportEmail' || location.pathname.includes('task-list') || dateOptions.dateSelection == 15) ? true : (localStorage.getItem('ENABLE_REAL_TIME') === 'true') ? false : true}
                                                id="realTime_data"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <span className="toggleText exclude-text"
                                            style={(location.pathname.includes('app-insights') ||
                                                location.pathname.includes('dashboard') ||
                                                location.pathname.includes('organization') ||
                                                props.source === 'modal' ||
                                                props.source === 'exportEmail' ||
                                                location.pathname.includes('task-list') ||
                                                dateOptions.dateSelection == 15)
                                                ? { color: '#8e8fa6' }
                                                : (localStorage.getItem('ENABLE_REAL_TIME') === 'true') ? { color: '#26273b' } : { color: '#8e8fa6' }}>
                                            {insightsLbls.includeRealTimeData}
                                        </span>
                                    </div>

                                    <div className="marginTop-10 datePicker-optns">
                                        <label className="custom-check">
                                            <input
                                                type="checkbox"
                                                ref={excludeOption}
                                                disabled={location.pathname.includes('app-insights') ||
                                                    location.pathname.includes('dashboard') ||
                                                    location.pathname.includes('organization') ||
                                                    location.pathname.includes('automation-insights') ||
                                                    location.pathname.includes('survey') ||
                                                    location.pathname.includes('chatbot') ||
                                                    location.pathname.includes('task-list') ||
                                                    props.source === 'modal' || props.source === 'exportEmail'}
                                                id="exclude_weekend"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <span className="toggleText  exclude-text"
                                            style={(location.pathname.includes('app-insights') ||
                                                location.pathname.includes('dashboard') ||
                                                location.pathname.includes('organization') ||
                                                location.pathname.includes('automation-insights') ||
                                                location.pathname.includes('survey') ||
                                                location.pathname.includes('chatbot') ||
                                                location.pathname.includes('task-list') ||
                                                props.source === 'modal' || props.source === 'exportEmail')
                                                ? { color: '#8e8fa6' }
                                                : { color: '#26273b' }}>
                                            {insightsLbls.excludeWeekends}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button
                                        className="applyDate-btn btn btn-outline-secondary "
                                        onClick={(ev) => applyDate(dateOptions.dateSelection, ev)}
                                    >
                                        {" "}
                                        {insightsLbls.apply}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                {maxDate.maxDateSetFlag ? (
                    <div className="max180Days-error">{insightsLbls.selectDateRangeWithin180Days}</div>
                ) : null}
            </div>
        </div>

    );
};

export default withRouter(React.memo(Datepicker));