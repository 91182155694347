import * as actionTypes from '../../Actions/ActionTypes';

export interface TooltipsDetailState {
    tooltipsDetailListData: any
    tooltipsDetailChartData: any
    tooltipsDetailPowerformChartData: any;
    tooltipsDetailUserActivity: any;
    tooltipDetailsLinkClickData: any,
    linkEventSummary: any,
}

const initialState = {
    tooltipsDetailListData: [],
    tooltipsDetailChartData: [],
    tooltipsDetailPowerformChartData: [],
    tooltipsDetailUserActivity: [],
    tooltipDetailsLinkClickData: [],
    linkEventSummary: []
};


const tooltipsDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case actionTypes.SET_TOOLTIPS_DETAIL_LIST_DATA:
            return {
                ...state,
                tooltipsDetailListData: action.tooltipsDetailListData,
            };

        case actionTypes.SET_TOOLTIPS_DETAIL_CHART:
            return {
                ...state,
                tooltipsDetailChartData: action.tooltipsDetailChartData,
            };

        case actionTypes.SET_TOOLTIPS_DETAIL_POWERFORM_CHART:
            return {
                ...state,
                tooltipsDetailPowerformChartData: action.tooltipsDetailPowerformChartData,
            };

        case actionTypes.SET_TOOLTIPS_DETAIL_USER_ACTIVITY:
            return {
                ...state,
                tooltipsDetailUserActivity: action.tooltipsDetailUserActivity,
            };

        case actionTypes.SET_TOOLTIPS_DETAIL_LINK_CLICK_DATA:
            return {
                ...state,
                tooltipDetailsLinkClickData: action.tooltipDetailsLinkClickData,
            };

        case actionTypes.SET_LINK_EVENT_SUMMARY:
            return {
                ...state,
                linkEventSummary: action.linkEventSummary,
            };

        default:
            return state;
    }
};

export default tooltipsDetailReducer;